<template>
  <ModalEmpty v-if="isOpenModalReload" @close="isOpenModalReload = false">
    <form id="form-reload" class="modal-reload grid ggap-20 p-30" @submit.prevent="reloadAgreement(true)">
      <h1 class="title">Опишите причину перезапуска согласования</h1>
      <FormTextarea label="" placeholder="Описание причины" rows="5" required v-model="commentary" />
      <div class="flex ai-c jc-sb">
        <button class="btn cancel" type="button" @click.prevent="isOpenModalReload = false">Отмена</button>
        <button class="btn primary" type="submit">Перезапустить согласование</button>
      </div>
    </form>
  </ModalEmpty>

  <ModalEmpty v-if="isOpenModalCancel" @close="isOpenModalCancel = false">
    <form
      id="form-cancel"
      class="modal-cancel grid ggap-20 p-30 w-40vw pos-r b-rad overflow-h"
      @submit.prevent="approveHandler(false)"
    >
      <BaseLoad v-if="isLoad" class="white z99999" />
      <h1 class="title">Опишите причину отклонения</h1>
      <FormEditor
        label=""
        placeholder="Описание причины отклонения, например: не достаточно информации..."
        class="big"
        required
        v-model="reasonMessage"
      />
      <div class="flex ai-c jc-sb">
        <button class="btn cancel" type="button" @click.prevent="isOpenModalCancel = false">Отмена</button>
        <button class="btn primary" type="submit">Отправить комментарий, и отклонить заявку</button>
      </div>
    </form>
  </ModalEmpty>

  <ModalEmpty v-if="isOpenModalDelete" @close="isOpenModalDelete = false">
    <div class="p-30 flex fd-c ggap-20">
      <h1 class="title">Вы уверены что хотите удалить заявку?</h1>
      <div class="flex ai-c jc-sb">
        <button class="btn gray" type="button" @click.prevent="isOpenModalDelete = false">Отмена</button>
        <button class="btn red" type="submit" @click.stop="deleteAction">Удалить</button>
      </div>
    </div>
  </ModalEmpty>

  <ModalFull :content-class="{ sm: isNew }" @close="closeAction">
    <template #head>
      <div class="flex ai-c ggap-10 jc-sb">
        <div class="flex ai-c ggap-10">
          <h1 class="title">Заявка №{{ route.params && route.params.id }}</h1>
          <small
            v-if="!isLoad && item?.status"
            class="marker"
            :style="{ color: item?.status?.text_color, backgroundColor: item?.status?.background_color }"
          >
            {{ item?.status?.name }}
          </small>
        </div>
        <div class="flex ai-c ggap-10">
          <RouterLink
            v-if="
              ['in-head-company-approval', 'in-fin-approval', 'in-head-fin-approval', 'draft', 'in-rework'].includes(
                item?.status?.uid
              ) &&
              (isAccountant || isFinancier || isAuthor) &&
              !isLoad
            "
            :to="'/orders/edit/' + item?.id"
            class="btn grey"
          >
            <BaseIcon class="ic-20 primary" icon="edit" />
            Редактировать
          </RouterLink>
          <button
            @click.prevent.stop="isOpenModalDelete = true"
            class="btn red"
            v-if="isAccountant || isFinancier || (item?.status?.id === 10 && isAuthor && !isLoad)"
          >
            <BaseIcon class="ic-20 white" icon="delete" />
            Удалить заявку
          </button>
          <button @click.prevent.stop="approveHandler(true)" class="btn green" v-if="canShowApprove && !isLoad">
            <BaseIcon class="ic-20 white" icon="check-circle" />
            Согласовать
          </button>
          <button @click.prevent.stop="isOpenModalCancel = true" class="btn gray" v-if="canShowApprove && !isLoad">
            <BaseIcon class="ic-20 white" icon="close-circle" />
            Отказать
          </button>
          <button @click.prevent.stop="isOpenModalReload = true" class="btn green" v-if="canShowReload && !isLoad">
            <img :src="require('@/assets/img/repeat_24px.svg')" />
            Пересогласовать
          </button>
          <BaseDropDown
            direction="rtl"
            btn-class="btn grey fw-b pl-10 pr-10"
            btn-active-class="active"
            ref="statusDropDown"
            v-if="isAccountant || isFinancier"
          >
            <template #btn>
              <BaseIcon class="ic-20 black" icon="more-horizontal" />
            </template>
            <template #body>
              <div @click.stop class="statuses-drop-down grid ggap-5 p-5">
                <template v-for="status of filteredStatuses" :key="status.id">
                  <button
                    class="btn transparent-grey pl-10"
                    :class="{ active: item.status.id === status.id }"
                    @click="setStatusAction(status.id)"
                  >
                    <div
                      class="statuses-drop-down__dot bg-grey-dark flex ai-c jc-c"
                      :style="{ backgroundColor: status.background_color }"
                    />
                    {{ status.name }}
                  </button>
                </template>
              </div>
            </template>
          </BaseDropDown>
        </div>
      </div>
    </template>

    <template #default>
      <form class="order grid form-add" id="form-add" :class="{ 'gtc-1-auto': !isNew }">
        <BaseLoad v-if="isLoad" class="white z99999" />

        <template v-if="item">
          <div class="order__left overflow-a h-100" id="print-content">
            <div class="order__left-content">
              <section class="p-20 grid gtc-2 ggap-20">
                <ItemRead label="Тип оплаты">
                  {{ optionsPayType.find((i) => i.value === item.type)?.text || '-' }}
                </ItemRead>
                <ItemRead label="За что платим">
                  {{ item.pay_for || '-' }}
                </ItemRead>
              </section>
              <section class="p-20 grid gtc-2 ggap-20">
                <ItemRead label="Сумма">
                  {{ separator(item.cost) || '-' }}
                </ItemRead>
                <ItemRead label="Валюта">
                  {{ currencyOptions.find((i) => i.value === item.currency)?.text || '-' }}
                </ItemRead>
              </section>
              <section class="p-20 grid gtc-2 ggap-20">
                <ItemRead label="Номер договора">
                  {{
                    item.contract?.number
                      ? `${item.contract?.number} - ${item.contract?.date_to}`
                      : 'Договор не был выбран'
                  }}
                </ItemRead>
                <ItemRead label="День факт. оплаты">
                  {{ item.pay_date ? moment(item.pay_date).format('DD-MM-YYYY') : '-' }}
                </ItemRead>
              </section>

              <section class="p-20 grid gtc-2 ggap-20">
                <div class="grid gtc-1 ggap-20" v-if="item?.status?.id === 3 && isFinancier">
                  <FormDate
                    v-model="pay_date_planned"
                    type="date"
                    required
                    format="YYYY-MM-DD"
                    label="Выберите планируемый день оплаты"
                    placeholder="-"
                  />
                </div>
                <ItemRead label="День план. оплаты" v-else>
                  {{ item.pay_date_planned ? moment(item.pay_date_planned).format('DD-MM-YYYY') : '-' }}
                </ItemRead>
              </section>

              <section class="p-20 grid gtc-2 ggap-20">
                <ItemRead label="Назначение платежа">
                  {{
                    item?.payment_ground_type
                      ? paymentTypeOptions.find((el) => el.value === item?.payment_ground_type)?.text
                      : '-'
                  }}
                </ItemRead>
                <ItemRead
                  label="Номер счета от дата счета"
                  v-if="['payment_invoice', 'bill_invoice'].includes(item?.payment_ground_type)"
                >
                  {{
                    item?.payment_ground_number && item?.payment_ground_date
                      ? `${item?.payment_ground_number} от ${item?.payment_ground_date}`
                      : '-'
                  }}
                </ItemRead>
                <ItemRead label="Назначение платежа (другое)" v-if="['other'].includes(item?.payment_ground_type)">
                  {{ item?.payment_ground_purpose }}
                </ItemRead>
              </section>

              <section class="p-20 grid ggap-20">
                <ItemRead label="Комментарий инициатора">
                  <div
                    v-if="item.commentary"
                    class="editor-data break-space box p-15 pt-10 pb-10"
                    v-html="DOMPurify.sanitize(item.commentary)"
                  />
                  <template v-else>-</template>
                </ItemRead>
              </section>

              <section class="p-20 grid ggap-20">
                <ItemRead label="Прикрепленные файлы">
                  <div v-if="item.documents && item.documents.length" class="box grid overflow-h">
                    <ItemFile
                      v-for="file of item.documents"
                      :key="file.id"
                      :item="file"
                      :btns="{ download: isDownload(file.id) }"
                    />
                  </div>
                  <BaseAlert v-else type="yellow"> Прикрепленные файлы не найдены </BaseAlert>
                </ItemRead>
              </section>

              <section class="p-20 grid gtc-2 ggap-20">
                <ItemRead label="Проект">
                  <div v-if="item.company" class="grid ggap-5 box p-10">
                    <div class="grid gtc-1-auto ai-c ggap-10">
                      <strong class="cut">{{ item.company.project }}</strong>
                      <a href="#" @click.prevent="isOpenProject = !isOpenProject">реквизиты</a>
                    </div>
                    <strong class="fs-12 t-grey-dark">БИН: {{ item?.company?.bin || '-' }}</strong>
                  </div>
                  <div v-else>&mdash;</div>

                  <!-- Info -->
                  <div v-if="isOpenProject" class="box p-10 grid ggap-10">
                    <table>
                      <tr>
                        <td>Наименование:</td>
                        <td>{{ item.company.llp }}</td>
                      </tr>
                      <tr>
                        <td>БИН:</td>
                        <td>{{ item?.company?.bin || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Банк:</td>
                        <td>{{ item.company.bank }}</td>
                      </tr>
                      <tr>
                        <td>БИК:</td>
                        <td>{{ item.company.bik }}</td>
                      </tr>
                    </table>
                  </div>
                </ItemRead>

                <ItemRead label="Контрагент">
                  <div class="grid ggap-5 box p-10" v-if="item.counterparty">
                    <div class="grid gtc-1-auto ai-c ggap-10">
                      <div class="grid gtc-1-auto ggap-5">
                        <strong class="cut">{{ item?.counterparty?.company_name || '-' }}</strong>
                      </div>
                      <a href="#" @click.prevent="isOpenCounterparty = !isOpenCounterparty">реквизиты</a>
                    </div>
                    <strong class="fs-12 t-grey-dark">БИН: {{ item?.counterparty?.bin || '-' }}</strong>
                  </div>
                  <div v-else>&mdash;</div>
                  <div v-if="isOpenCounterparty" class="box p-10 grid ggap-10">
                    <table>
                      <tr>
                        <td>БИН:</td>
                        <td>{{ item?.counterparty?.bin || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Банк:</td>
                        {{
                          item.counterparty?.accountants?.find((i) => i.id === item.counterparty_account_id)
                            ?.bank_name || '-'
                        }}
                      </tr>
                      <tr>
                        <td>Счёт:</td>
                        <td>
                          {{
                            item.counterparty?.accountants?.find((i) => i.id === item.counterparty_account_id)?.iban ||
                            '-'
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>БИК:</td>
                        <td>
                          {{
                            item.counterparty?.accountants?.find((i) => i.id === item.counterparty_account_id)?.bik ||
                            '-'
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>КБЕ:</td>
                        <td>{{ item?.kbe?.code }}</td>
                      </tr>
                      <tr>
                        <td>КНП:</td>
                        <td>{{ item?.knp?.code }}</td>
                      </tr>
                    </table>
                  </div>
                </ItemRead>
              </section>

              <section class="p-20 grid gtc-1 ggap-20">
                <ItemRead label="Инициатор">
                  <div v-if="item.author" class="box p-5 grid gtc-auto-1 ggap-10 ai-c">
                    <div class="cover ava-50">
                      <img :src="getAvatar(item.author.avatar)" />
                    </div>
                    <div class="flex ai-c ggap-20">
                      <div class="grid ggap-5">
                        <a :href="profileLink + item.author.id">{{ cutFullName(item.author.fullname) }}</a>
                        <small class="t-grey-dark">{{ item.author.specialization || 'Нет должности' }}</small>
                      </div>
                      <button class="btn sm pl-10 pr-10 ml-auto" @click.prevent="isContactsAuthor = !isContactsAuthor">
                        контакты
                      </button>
                    </div>
                  </div>

                  <div v-if="isContactsAuthor" class="contacts flex fw ggap-5">
                    <template
                      v-if="
                        (item.author.contacts && item.author.contacts.length) ||
                        (item.contact_information && Object.keys(item.contact_information).length)
                      "
                    >
                      <a
                        v-for="contact of item.author.contacts"
                        :key="contact.id"
                        :href="getLink({ key: contact.type, value: contact.value })"
                        class="contacts__item box flex ai-c ggap-5 pt-5 pb-5 pl-10 pr-10"
                      >
                        <BaseIcon class="ic-14 grey" :icon="contact.type" />
                        {{ contact.value }}
                      </a>
                      <a
                        v-for="(value, key) in item.contact_information"
                        :key="key"
                        :href="getLink({ key, value })"
                        class="contacts__item box flex ai-c ggap-5 pt-5 pb-5 pl-10 pr-10"
                      >
                        <BaseIcon class="ic-14 primary" :icon="key" />
                        {{ value }}
                      </a>
                    </template>

                    <BaseAlert v-else class="w-100" type="yellow">Контакты не найдены</BaseAlert>
                  </div>
                </ItemRead>
              </section>

              <section class="p-20 grid gtc-1 ggap-20">
                <Responsible
                  :disabled="
                    ([2, 3, 4].includes(item?.status?.id) && isFinancier) || [1510, 1312].includes(profile?.id)
                  "
                  label="Согласующий"
                  :withoutMe="false"
                  v-model="approver"
                />
              </section>

              <section class="p-20 grid gtc-1 ggap-20" v-if="item?.approvers?.length">
                <table class="table">
                  <tr>
                    <td>Согласующие</td>
                    <td>Статус</td>
                  </tr>
                  <tr v-for="person in item.approvers" :key="person?.user?.id" class="box">
                    <td>
                      <div v-if="person.user" class="grid gtc-auto-1 ggap-10 ai-c">
                        <div class="cover ava-30">
                          <img :src="getAvatar(person.user.avatar)" />
                        </div>
                        <div class="flex ai-c ggap-20">
                          <div class="grid ggap-5">
                            <a :href="profileLink + person.user.id">{{ cutFullName(person?.user?.fullname) }}</a>
                            <small class="t-grey-dark">{{ person?.user?.specialization || 'Нет должности' }}</small>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="flex ai-c">
                        <span class="mr-10 ml-10">{{ person?.status }}</span>
                        <img
                          v-if="person?.status?.toLowerCase() === 'пересогласование'"
                          :src="require('@/assets/img/repeat_24px.svg')"
                        />
                        <img
                          v-if="person?.status?.toLowerCase() === 'ожидает'"
                          :src="require('@/assets/img/sand-clock_1.svg')"
                        />
                        <BaseIcon
                          v-if="person?.status?.toLowerCase() === 'согласовано'"
                          class="ic-20 green"
                          icon="check-circle"
                        />
                        <BaseIcon
                          v-if="['отменено', 'отказано'].includes(person?.status?.toLowerCase())"
                          class="ic-20 red"
                          icon="close-circle"
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </section>

              <section class="p-20 grid gtc-3 ggap-20 b-t">
                <ItemRead label="Дата создания">
                  <div class="flex ai-c ggap-10">
                    {{ formatRuDateTime(item.created_at) }}
                  </div>
                </ItemRead>
              </section>
            </div>
          </div>

          <div class="order__right overflow-h" v-if="![10, 7].includes(item.status.id)">
            <Sidebar :order="item" ref="sidebarRef" id="print-sidebar" @globaLoad="isLoad = $event" />
          </div>
        </template>

        <div v-else class="flex fd-c ai-c jc-c ggap-10">
          <BaseIcon class="ic-30 red" icon="alert-triangle" />
          <h2 class="title t-red">Ошибка загрузки</h2>
          <div>У вас недостаточно прав для просмотра заявки</div>
        </div>
      </form>
    </template>
  </ModalFull>
</template>

<script setup>
import { $busEmit, cutFullName, getAvatar, formatRuDateTime, isInvalidForm } from '@/plugins'
import {
  ModalFull,
  ItemRead,
  BaseLoad,
  BaseIcon,
  FormDate,
  ItemFile,
  ModalEmpty,
  BaseDropDown,
  FormEditor,
  FormTextarea,
  Responsible
} from '@/components'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, ref, inject, watch, nextTick } from 'vue'
import Sidebar from './components/sidebar/Index.vue'
import orders from '../../api/modules/orders'
import { approveAction } from './components/approve.js'
import socketConnect from '@/plugins/socket-connect'
import moment from 'moment'
import DOMPurify from 'dompurify'

const profileLink = inject('profileLink')
const route = useRoute()
const router = useRouter()
const store = useStore()
const isOpenCounterparty = ref(false)
const isOpenModalCancel = ref(false)
const isOpenModalDelete = ref(false)
const isContactsAuthor = ref(false)
const isOpenProject = ref(false)
const isLoad = ref(false)

const reasonMessage = ref('')
const sidebarRef = ref()
const isOpenModalReload = ref(false)
const pay_date_planned = ref('')
const approver = ref('')
const statusDropDown = ref()
const commentary = ref('')

watch(approver, () => {
  if (!isLoad.value) {
    setApprover()
  }
})

store.dispatch('helpers/GET_STATUSES')

const paymentTypeOptions = [
  {
    text: 'Счет на оплату',
    value: 'payment_invoice'
  },
  {
    text: 'Счет фактура',
    value: 'bill_invoice'
  },
  {
    text: 'Договор',
    value: 'contract_number'
  },
  {
    text: 'Другое',
    value: 'other'
  }
]

// COMPUTED
const profile = computed(() => store.getters.profile)
const optionsStatuses = computed(() => store.getters['helpers/statuses'] || [])
const filteredStatuses = computed(() => optionsStatuses.value.filter((el) => [9, 8].includes(el.id)))
const item = computed(() => store.getters['orders/item'])
const optionsPayType = computed(() => store.getters['orders/optionsPayType'])
const currencyOptions = computed(() => store.getters['orders/currencyOptions'])
const isAuthor = computed(() => item.value?.author?.id === profile.value?.id)

const isFinancier = computed(() => store.getters['isFinancier'])
const isAccountant = computed(() => store.getters['isAccountant'])
const isHeadFinancier = computed(() => store.getters['isHeadFinancier'])
const isDirector = computed(() => item.value?.head === profile.value.id)
const canShowApprove = computed(() => {
  if (item.value?.status?.id === 2) return isDirector.value
  if (item.value?.status?.id === 3) return isFinancier.value
  if (item.value?.status?.id === 4) return isHeadFinancier.value
  return false
})
const canShowReload = computed(() => {
  if (item.value?.status?.id === 3) return isDirector.value
  if (item.value?.status?.id === 4) return isFinancier.value
  if (item.value?.status?.id === 5) return isHeadFinancier.value
  return false
})

// CREATED
getItem()
wsConnect()

// METHODS
async function setApprover() {
  const id = Number(route.params.id)
  try {
    await orders.setApprover(id, approver.value?.id)
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Согласующий успешно сохранен!'
    })
  } catch (err) {
    console.log(err)
    $busEmit('setToast', {
      type: 'red',
      icon: 'close-circle',
      message: 'При сохранении согласующего произошла ошибка'
    })
  } finally {
    isLoad.value = false
  }
}

async function setStatusAction(status) {
  try {
    isLoad.value = true
    await orders.updateStatus(item.value?.id, { status_id: status })
    await store.dispatch('orders/GET_ROWS')
    await getItem()
    closeAction()
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Заявка успешно обновлена!'
    })
  } finally {
    isLoad.value = false
  }
}

function separator(n) {
  var parts = n.toString().split('.')
  const numberPart = parts[0]
  const decimalPart = parts[1]
  const thousands = /\B(?=(\d{3})+(?!\d))/g
  return numberPart.replace(thousands, ' ') + (decimalPart ? '.' + decimalPart : '')
}

function closeAction(update = true) {
  router.replace('/orders')
  update
  // && store.commit('orders/UPDATE_ROW', item.value)
}

function isDownload(fileId) {
  const index = item.value.documents.findIndex((el) => el.id === fileId)
  if (index !== -1) return true
  else if (item.value?.status?.id === 8) return true
  else return true
}

async function approveHandler(flag) {
  if (flag && isInvalidForm('form-add')) return
  isLoad.value = true
  approveAction(route.params.id, flag, reasonMessage.value, pay_date_planned.value)
    .then(() => {
      orders.get(route.params.id).then((row) => {
        store.commit('orders/UPDATE_ROW', row.data.data)
        closeAction()
        $busEmit('setToast', {
          type: 'green',
          icon: 'check-circle',
          message: `Заявка была успешно ${flag ? 'согласована' : 'отклонена'}!`
        })
        store.dispatch('orders/GET_ROWS')
        isLoad.value = true
      })
    })
    .catch((err) => {
      console.log(err)
      $busEmit('setToast', {
        type: 'red',
        icon: 'close-circle',
        message: 'При согласовании произошла ошибка, обратитесь к Администратору!'
      })
    })
}

function getLink({ key, value }) {
  switch (key) {
    case 'phone':
      return 'tel:' + value

    case 'email':
      return 'mailto:' + value

    case 'whatsapp':
      return 'https://wa.me/' + value

    case 'telegram':
      return 'tg://resolve?domain=' + value

    default:
      return value
  }
}

async function reloadAgreement(flag) {
  isLoad.value = true
  isOpenModalReload.value = false
  approveAction(route.params.id, flag, commentary.value, pay_date_planned.value, true)
    .then(() => {
      orders.get(route.params.id).then((row) => {
        store.commit('orders/UPDATE_ROW', row.data.data)
        closeAction()
        $busEmit('setToast', {
          type: 'green',
          icon: 'check-circle',
          message: `Заявка была успешно пересогласована!`
        })
        store.dispatch('orders/GET_ROWS')
        isLoad.value = true
      })
    })
    .catch((err) => {
      console.log(err)
      $busEmit('setToast', {
        type: 'red',
        icon: 'close-circle',
        message: 'При согласовании произошла ошибка, обратитесь к Администратору!'
      })
    })
}

async function deleteAction() {
  const id = route.params.id
  try {
    await orders.delete(id)
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Заявка успешно удалена!'
    })
    store.commit('orders/DELETE_ROW', id)
  } catch (err) {
    console.log(err)
    $busEmit('setToast', {
      type: 'red',
      icon: 'close-circle',
      message: 'При удалении заявки произошла ошибка, повторите позже или обратитесь к Администратору!'
    })
  } finally {
    isLoad.value = false
    closeAction()
  }
}

async function getItem(withLoad = true) {
  withLoad && (isLoad.value = true)
  await store.dispatch('orders/GET_ITEM', route.params.id)
  pay_date_planned.value = item.value.pay_date_planned
  approver.value = {
    text: item.value?.participant_approver?.user?.fullname,
    id: item.value?.participant_approver?.user?.id,
    avatar: item.value?.participant_approver?.user?.avatar
  }
  await nextTick()
  isLoad.value = false
}

function wsConnect() {
  socketConnect
    .channel('financier.application.status')
    .listen('.FinancierApplicationListStatusChangedBroadcast', (res) => {
      res.application_id === Number(route.params.id) && getItem(false)
    })
}
</script>

<style lang="scss" scoped>
.table,
.table tr,
.table td {
  border: var(--b1);
  border-collapse: collapse;
  padding: 5px;
}
.modal {
  &-final,
  &-cancel {
    width: 600px;
  }
}
.statuses-drop-down {
  max-height: calc(100vh - 200px);
  overflow: auto;

  &__dot {
    width: 10px;
    height: 10px;
    border-radius: 100px;

    & > span {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 100px;
    }
  }
}
.order {
  position: relative;
  max-height: calc(95vh - 50px);

  &__left {
    &-content {
      &.hide {
        height: 200px;
        overflow: hidden;
        position: relative;
        z-index: 1;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: var(--form);
          opacity: 0.8;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
        }
      }

      &.bordered {
        border-bottom: var(--b1);
      }

      .editor-data {
        // background-color: var(--grey-l);
      }

      .contacts {
        &__item {
          text-decoration: none;
          color: var(--text);

          &:hover {
            background-color: var(--grey-l);
          }
        }
      }
    }

    &-result {
      position: relative;

      &-btn {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }
    }
  }

  &__foot {
    border-top: var(--b1);
  }
}
</style>
