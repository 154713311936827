<template>
  <div class="sidebar grid gtr-auto-1-auto">
    <!-- Head -->
    <div class="sidebar__head flex ai-c jc-sb ggap-30 pl-20 pr-20">
      <!-- History -->
      <div class="flex ai-c ggap-10 jc-sb w-100">
        <div class="sidebar__head-search form-item sm grid pos-r" v-tippy="'Поиск по тексту сообщения'">
          <label for="message-search" class="sidebar__head-search-label flex ai-c jc-c pos-a">
            <BaseIcon class="ic-16 primary" icon="search" />
          </label>
          <input
            type="search"
            name="message-search"
            id="message-search"
            class="form-item__input pl-30 pr-5"
            placeholder="Поиск..."
            autocomplete="off"
            v-model="searchText"
          />
        </div>

        <BaseDropDown
          direction="rtl"
          btn-class="btn grey sm pl-10 pr-10"
          btn-active-class="active"
          ref="filterDropDown"
        >
          <template #btn>
            <div class="grid gtc-auto-1 ggap-5 ai-c" v-tippy="'Фильтр: ' + currentFilterItem.text">
              <BaseIcon class="ic-16 primary" icon="filter" />
              <div class="cut">{{ currentFilterItem.text }}</div>
            </div>
          </template>
          <template #body>
            <div class="box p-5 grid ggap-5" @click.stop>
              <button
                v-for="filter of optionsFilter"
                :key="filter.value"
                class="btn transparent-grey w-100 sm"
                :class="{ active: currentFilter === filter.value }"
                @click.stop="setFilter(filter)"
              >
                <div class="fs-18">
                  {{ filter.icon }}
                </div>
                {{ filter.text }}
              </button>
            </div>
          </template>
        </BaseDropDown>
      </div>
    </div>

    <!-- Body -->
    <component
      :is="currentComponent"
      :isHasEdit="isHasEdit"
      :searchText="searchText"
      :currentFilter="currentFilter"
      :isAll="isAll"
      :order="order"
      ref="sidebarHistory"
    />
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref, computed, defineAsyncComponent, defineExpose } from 'vue'
import { BaseIcon, BaseDropDown } from '@/components'

// Props
const props = defineProps(['order'])

// Data
const { order } = toRefs(props)
const sidebarHistory = ref()
const currentTab = ref('history')

const searchText = ref('')
const currentFilterItem = ref({
  icon: '📚',
  text: 'Все записи',
  value: ''
})
const filterDropDown = ref()
const currentFilter = ref('')
const optionsFilter = ref([
  {
    icon: '📚',
    text: 'Все записи',
    value: ''
  },
  {
    icon: '💬',
    text: 'Только сообщения',
    value: 'comment'
  },
  {
    icon: '📎',
    text: 'Только сообщения с файлами',
    value: 'doc'
  },
  {
    icon: '📝',
    text: 'Только изменения',
    value: 'changed'
  }
])
const isAll = ref(false)

// Computed
const currentComponent = computed(() => currentTab.value && defineAsyncComponent(() => import('./history/Body.vue')))
const isHasEdit = computed(() => {
  return false
})

// Methods
function getRows() {
  sidebarHistory.value?.getRows()
}
function setFilter(filter) {
  currentFilterItem.value = { ...filter }
  currentFilter.value = filter.value
  filterDropDown.value?.closeAction()
}

// Expose
defineExpose({
  getRows
})
</script>

<style lang="scss">
.sidebar {
  width: 550px;
  height: 100%;
  border-left: var(--b1);
  background-color: var(--form);

  &__head {
    background-color: var(--box);
    border-bottom: var(--b1);
    height: 50px;

    &-search-label {
      height: 100%;
      width: 30px;
    }
  }

  &__gobottom {
    left: 20px;
    bottom: 10px;
    z-index: 100;
  }
}
</style>
